<template>
    <!--<img src="@/assets/logo.svg"/>-->
    <svg xmlns="http://www.w3.org/2000/svg" version="1.2" baseProfile="tiny" viewBox="0.00 0.00 637.00 555.00">
    <path fill="#07263c" d="
      M 314.84 0.00
      L 321.09 0.00
      Q 335.06 1.05 344.25 5.53
      Q 351.76 9.19 358.76 13.78
      Q 382.12 29.11 405.30 43.67
      Q 406.52 44.44 406.59 43.01
      C 407.14 32.01 411.86 21.97 420.61 15.48
      Q 428.58 9.57 436.74 8.14
      Q 449.77 5.87 459.81 9.28
      Q 481.03 16.50 484.86 38.08
      Q 485.88 43.86 485.69 51.76
      C 485.32 66.59 485.49 81.82 485.51 96.02
      A 2.47 2.44 -72.8 0 0 486.56 98.04
      Q 491.01 101.17 494.08 103.16
      Q 546.39 137.06 598.54 170.95
      C 602.97 173.82 607.45 176.13 612.08 178.46
      Q 624.56 184.75 631.66 196.81
      Q 636.24 204.59 637.00 214.58
      L 637.00 219.11
      Q 635.94 232.40 627.92 241.68
      Q 616.64 254.72 597.98 255.20
      C 592.12 255.35 586.08 255.38 580.17 255.06
      C 573.91 254.72 569.05 257.28 569.04 264.35
      Q 568.92 374.01 569.07 478.61
      Q 569.09 490.20 567.87 496.92
      C 562.23 527.97 536.38 552.16 504.65 554.50
      Q 501.31 554.74 497.96 555.00
      L 137.95 555.00
      Q 101.48 553.23 81.37 525.72
      C 73.92 515.53 69.18 503.34 68.28 491.10
      Q 67.89 485.78 67.86 480.00
      Q 67.71 454.19 67.95 421.75
      Q 67.96 421.13 67.96 420.50
      Q 68.00 344.25 68.06 268.00
      Q 68.07 263.24 66.65 259.71
      C 64.80 255.08 60.11 255.05 55.77 255.14
      Q 46.58 255.35 36.10 255.15
      Q 22.26 254.89 11.71 244.80
      Q 1.62 235.15 0.00 219.86
      L 0.00 214.70
      Q 2.60 191.64 22.74 179.93
      Q 26.16 177.94 30.30 176.10
      Q 34.66 174.17 37.74 172.16
      Q 86.63 140.26 131.46 110.94
      Q 170.83 85.19 208.52 60.48
      Q 241.76 38.69 279.06 14.08
      Q 286.32 9.29 294.05 5.31
      Q 302.17 1.14 314.84 0.00
      Z
      M 335.22 84.23
      C 328.12 80.09 320.56 78.15 312.32 79.82
      Q 305.66 81.17 299.12 85.36
      Q 284.77 94.55 270.23 103.66
      Q 255.26 113.04 255.25 130.86
      Q 255.25 145.49 255.27 160.49
      C 255.28 171.56 260.24 179.98 269.32 185.96
      Q 274.97 189.68 280.01 192.74
      Q 280.95 193.31 281.90 192.75
      Q 297.02 183.92 314.34 182.75
      C 329.33 181.74 343.14 185.29 355.95 192.78
      Q 356.47 193.08 356.99 192.79
      Q 368.01 186.53 373.47 181.38
      Q 381.39 173.92 381.87 163.97
      Q 382.53 150.41 382.20 130.72
      Q 381.91 113.25 369.60 105.20
      Q 356.50 96.64 335.22 84.23
      Z
      M 472.40 195.04
      A 34.70 34.70 0.0 0 0 437.70 160.34
      A 34.70 34.70 0.0 0 0 403.00 195.04
      A 34.70 34.70 0.0 0 0 437.70 229.74
      A 34.70 34.70 0.0 0 0 472.40 195.04
      Z
      M 233.89 195.08
      A 34.67 34.67 0.0 0 0 199.22 160.41
      A 34.67 34.67 0.0 0 0 164.55 195.08
      A 34.67 34.67 0.0 0 0 199.22 229.75
      A 34.67 34.67 0.0 0 0 233.89 195.08
      Z
      M 369.56 257.92
      A 50.88 50.88 0.0 0 0 318.68 207.04
      A 50.88 50.88 0.0 0 0 267.80 257.92
      A 50.88 50.88 0.0 0 0 318.68 308.80
      A 50.88 50.88 0.0 0 0 369.56 257.92
      Z
      M 396.34 287.60
      C 388.17 292.95 383.75 301.76 383.75 311.51
      Q 383.76 331.22 383.82 351.78
      Q 383.87 368.51 398.21 377.54
      Q 414.41 387.75 429.96 397.24
      Q 447.02 407.65 464.34 397.06
      Q 477.73 388.87 495.05 377.96
      C 500.83 374.32 506.20 369.30 508.46 362.74
      Q 509.98 358.35 510.01 351.33
      Q 510.12 322.77 509.85 310.48
      Q 509.51 294.55 494.92 285.86
      Q 485.09 280.00 472.81 272.51
      Q 459.81 264.59 455.38 263.19
      Q 443.48 259.42 431.05 265.79
      Q 424.70 269.04 396.34 287.60
      Z
      M 139.28 288.31
      C 132.71 292.67 127.74 300.66 127.62 308.56
      Q 127.28 331.49 127.29 352.04
      Q 127.29 368.46 141.19 377.21
      Q 158.09 387.85 174.05 397.54
      C 186.26 404.95 197.92 403.43 209.47 396.27
      Q 224.61 386.87 240.32 376.84
      Q 253.71 368.28 253.55 351.97
      Q 253.37 333.85 253.58 317.66
      C 253.76 303.62 251.35 293.91 239.18 286.24
      Q 225.90 277.87 211.51 269.32
      Q 205.64 265.84 200.52 263.76
      C 191.41 260.05 180.91 262.31 172.24 267.44
      Q 158.41 275.64 139.28 288.31
      Z
      M 336.53 494.05
      Q 348.66 486.92 362.18 478.67
      Q 368.04 475.10 371.99 471.92
      C 379.47 465.88 382.07 457.90 382.19 448.55
      Q 382.43 427.53 382.11 409.91
      Q 382.07 407.56 381.81 404.91
      C 380.85 394.74 375.34 387.94 367.02 382.78
      Q 355.96 375.92 343.87 368.47
      Q 331.20 360.66 326.15 359.26
      Q 314.13 355.93 303.02 362.42
      Q 291.78 368.98 279.96 376.71
      C 274.62 380.20 268.93 383.23 264.27 387.44
      C 257.21 393.82 255.02 402.42 255.40 411.93
      C 255.86 423.36 255.54 435.11 255.41 446.81
      Q 255.31 454.98 257.04 460.00
      Q 260.14 469.01 268.91 474.47
      Q 284.47 484.14 299.73 493.59
      Q 318.03 504.92 336.53 494.05
      Z"
    />
    </svg>
</template>

<script>
</script>

<style>
</style>
